<template>
    <dialog ref="dialogRef" role="dialog" class="o365-login-wrapper d-flex flex-column align-items-center" id="waves"  >
        <div>
            <div class="overlay" v-if="isBusy">
                <div class="overlay__inner">
                    <div class="overlay__content"><span class="spinner"></span></div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column align-items-center bg-white rounded shadow p-3 w-100 login-body " style="max-width: 450px;" autofocus tabindex="-1"> 
            <div class="d-flex flex-column align-items-stretch w-100" style="max-width: 375px;min-height: 110px;">
                <a v-if="supportUrl && supportUrl.length" :href="supportUrl" style="align-self: end;" target="_blank">Help</a>
                <component v-if="stateObj" :is="getComponentFromName(currentComponent)" :state="stateObj" :errors="errors" :capsLockOn="capsLockOn"/> 
            </div>
            <div class="p-3 w-100 o365-login-logo"></div>
        </div>
    </dialog>
</template>

<style>

dialog {
    padding-top: 70px !important;
}

.login-body {
    outline: none;
}

.grecaptcha-badge {
    z-index: 9999!important;
}

.overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: #555;
    z-index: 99;
    opacity: 0.4;
    border-radius: var(--bs-border-radius);
}

.overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.overlay__content {
    left: 50%;
    position: absolute;
    top: 20%;
    transform: translate(-50%, -50%);
}

.spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #ffff;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
}


.o365-login-wrapper {
    font-size: 13px;
    background-color: rgb(6 26 52 / 100%);
    box-shadow: inset 0 0 0 100vmax rgb(6 26 52 / 55%);
    width: 100vw;
    max-width:100%;
    min-height: 100vh;
    font-family: Roboto, sans-serif;
    background-image: v-bind(loginWrapperBg); 
    background-repeat: no-repeat; 
    background-size: 75%; 
    background-position: bottom right;
}

.o365-login-logo {
    background-image: v-bind(loginLogo); 
    background-repeat: no-repeat; 
    background-position: center center;
    height: 150px;
}
@media (min-width: 769px) {
    .o365-login-wrapper {
        padding-bottom: 10vh!important;
    }
}
.o365-login-wrapper a[type=button]{
    color: black;
    text-decoration: underline;
}
.o365-login-wrapper a[type=button]:hover, 
.o365-login-wrapper a[type=button]:focus {
    color: black;
    text-decoration: none;
}


.o365-login-wrapper ul.nav-tabs {
    --bs-nav-tabs-border-color: none;
}
.o365-login-wrapper ul.nav-tabs .nav-link {
    /* border-bottom: 1px solid var(--bs-border-color)!important; */
    border-bottom: transparent !important;
}
.o365-login-wrapper ul.nav-tabs .nav-link.active {
    border-bottom-color: transparent!important;
}
.o365-login-wrapper ul.nav-tabs .nav-item.show .nav-link, 
.o365-login-wrapper .nav-tabs .nav-link.active {
    color: black;
    background-color: transparent;
}
.o365-login-wrapper .form-floating>.form-control, 
.o365-login-wrapper .form-floating>.form-control-plaintext, 
.o365-login-wrapper .form-floating>.form-select {
    height: calc(3rem + calc(var(--bs-border-width) * 2))!important;
    min-height: calc(3rem + calc(var(--bs-border-width) * 2))!important;
}
.o365-login-wrapper .form-floating>.form-control {
    padding: 0.8rem 0.5rem 0;
}
.o365-login-wrapper .form-floating>.form-control#password {
    padding-right: 40px!important;
}
.o365-login-wrapper .form-floating>label {
    color: black;
    padding: 0.8rem 0.5rem;
}
.o365-login-wrapper .btn-show-pass {
    position: absolute;
    top: 5px;
    bottom: 5px;
    right: 5px;
    padding: 5px;
    opacity: .5;
    font-size: 20px;
}
.o365-login-wrapper .btn-o365-login {
    width: 100%;
    padding: .5em;
    color: #fff;
    background-color: steelblue;
    border-color: steelblue;

}
.o365-login-wrapper .btn-o365-login:hover, 
.o365-login-wrapper .btn-o365-login:focus {
    color: white;
    background-color: rgb(6 26 52 / 90%);
    text-decoration: underline;
}


.o365-login-wrapper .btn-o365-secondary {
    color: white;
    background-color: rgb(6 26 52 / 100%);
 
    --bs-btn-padding-y: 0.25rem;
    --bs-btn-padding-x: 0.5rem;
    --bs-btn-font-size: 0.875rem;
}
.o365-login-wrapper .btn-o365-secondary:hover, 
.o365-login-wrapper .btn-o365-secondary:focus {
    color: white;
    background-color: rgb(6 26 52 / 90%);
    text-decoration: underline;
}
.btn-show-pass:focus-visible {
    outline: 1px solid black;
}
.support-link{
    text-decoration:none!important;
}
.support-link:hover{
    text-decoration:underline!important;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>

<script setup lang="ts">
    import {  ref, onMounted, onUnmounted, computed, provide, onBeforeMount } from 'vue';
    import {  getComponentFromName, createRequest, MultiFactorStates, getQueryParam, getReturnUrl } from 'o365.modules.Login.shared.js';
    import FunctionBroadcastChannel from 'o365.modules.FunctionBroadcastChannel.ts';
    import { cdnBaseUrl } from 'o365.modules.helpers.js';
    import('o365.modules.helpers.js').then(async helpers => { 
        const customCssFileName = 'login.custom.css';
        const libUrl = await helpers.getLibUrl(customCssFileName);
        console.log('login css: ', libUrl);
        if (libUrl != customCssFileName) {
            helpers.loadStyle(libUrl)
        }
    });

    const loginLogo = 'url('+cdnBaseUrl + '/publicweb/omega-365-logo.svg)';
    const loginWrapperBg = 'url('+cdnBaseUrl + '/publicweb/login-waves.svg)';

    provide('updateState', updateState);
    provide('resetState', resetState);
    provide('isBusy', changeIsBusy);
    provide('authenticated', authenticationComplete);
    provide('close', closeDialog);

    provide('setErrors', setErrors);
    provide('setSuccess', setSuccess);

    const props = defineProps({
        initialOptions: {
            type: Object, required: false
        }
    });
    
    const returnUrl = getReturnUrl() ?? "/";
    const emit = defineEmits(['close']);
    const isBusy = ref(true);
    var isLoginArticle:Boolean = location.pathname.endsWith('/login');
    var isMfaArticle:Boolean = location.pathname.endsWith('/login/mfa');
    var isIframe:Boolean = window.location !== window.parent.location;
    var hasReturnUrlParam:Boolean = getQueryParam('ReturnUrl') !== null;
    const stateObj = ref<{
        isAuthenticated: Boolean,
        rememberMeDefaultValue: Boolean,
        multiFactorState: number,
        authentication: {
            enabled: Boolean,
            requireTwoFactor: Boolean,
            index: number
        }
        action: string,
        scheme: string,
        currentProvider: string
    }>(null!);

    const errors = ref({});
    const dialogRef = ref<HTMLDialogElement>();
    const capsLockOn = ref(false);

    const supportUrl = document.querySelector("meta[name='supportUrl']")?.getAttribute("content");

    const broadcast = new FunctionBroadcastChannel({
        id: 'login.authenticated',
        functions: {
            'authenticated': () => authenticationComplete()
        }
    });

    function updateState(newState?:{action?:string, multiFactorState?:number, scheme?:string, currentProvider?:string}){
        stateObj.value["updated"] = Date.now();
        if(newState?.action && stateObj.value.action !== newState.action){
            stateObj.value.action = newState.action;
        }
        if(newState?.multiFactorState != null && stateObj.value.multiFactorState !== newState.multiFactorState){
            stateObj.value.multiFactorState = newState.multiFactorState;
        }
        if(newState?.scheme && stateObj.value.scheme != newState.scheme){
            stateObj.value.scheme = newState.scheme;
        }
        if(newState?.currentProvider != null){
            stateObj.value.currentProvider = newState.currentProvider;
        }
        if(stateObj.value.scheme == 'SmsToken' && newState?.multiFactorState == MultiFactorStates.Verified){
            authenticationComplete(stateObj.value.scheme);
        }
    }

    async function authenticationComplete(name?:string){
        console.log('Authenticated');
        
        if(isLoginArticle && name && stateObj.value.authentication[name]?.requireTwoFactor){
            await getState();
            return;
        }

        broadcast.execute('authenticated');
        closeDialog();        
    }

    async function resetState(){
        var state = stateObj.value ?? {};
        state["updated"] = Date.now();
        errors.value = {};
        var oldState = state['multiFactorState'];
        var oldAction = state['action'];
        state['action'] = 'login'
        if(oldState == 0 && oldAction == 'login') return;
        var resp = await createRequest("/api/login/mfa", { back: true });
        if(resp.ok){
            var json = await resp.json();
            updateState(json);
        }
    }

    function changeIsBusy(busy){
        isBusy.value = busy;
    }

    function setErrors(err){
        if(typeof err === "string"){
            errors.value = { error: err };
        } else if(typeof err === "object"){
            errors.value = err;
        }
        if(errors.value){
            console.warn('Errors updated: ', errors.value);
        }
    }

    function setSuccess(successMessage:String){
        errors.value = { success: successMessage };
        console.warn('Success: ', errors.value);
    }

    const currentComponent = computed(() => {
        let state = stateObj.value;
        console.log("Updating currentComponent");
        switch(state.action) {
            case 'signup': 
            case 'reset': {
                if(getQueryParam('newaccount') && getQueryParam('token')){
                    return 'signup';
                }
                if(state.multiFactorState == MultiFactorStates.Login) {
                    return 'mfa';
                }
                else if(state.multiFactorState == MultiFactorStates.Verify) {
                    return 'verify';
                }
                else if(state.multiFactorState == MultiFactorStates.Verified){
                    if(state.isAuthenticated) { 
                        authenticationComplete();
                    }
                    return 'signup';
                }
            }
            case 'login': {
                if(isMfaArticle) {
                    if(state.multiFactorState == MultiFactorStates.Verify) {
                        return 'verify';
                    }else if(state.multiFactorState == MultiFactorStates.Register && state.isAuthenticated){
                        return 'register';
                    }
                    if(!state.isAuthenticated) {
                        window.location.pathname = '/login';
                    }
                    else if(state.multiFactorState == MultiFactorStates.Verified) {
                        authenticationComplete();
                        return 'login';
                    }
                    return 'mfa';
                }
                if(state.isAuthenticated && state.multiFactorState == MultiFactorStates.Verified){
                    authenticationComplete();
                }
                if(state.multiFactorState == MultiFactorStates.Verify){
                    return 'verify';
                }
                return 'login';
            }
            case 'fido2': {
                return 'fido2';
            }
            default: return 'login';
        }        
    });
    
    async function getState(){
        try {
            const response = await createRequest('/api/login/state' + location.search, null);
            if(response.ok){
                var json = await response.json();

                if(props.initialOptions){
                    if(props.initialOptions.state) json.multiFactorState = props.initialOptions.state;
                    if(props.initialOptions.action) json.action = props.initialOptions.action;
                    if(props.initialOptions.username) json.username = props.initialOptions.username;
                }

                if(isLoginArticle && json.isAuthenticated && hasReturnUrlParam && json.action === 'login' || 
                    isMfaArticle && json.isTwoFactorVerified && json.action === 'login') {
                    return authenticationComplete();
                }

                if(!isLoginArticle && !isMfaArticle && json.isAuthenticated && json.action === 'login' && !json.isTwoFactorVerified){
                    isMfaArticle = true;
                }
                if(json.error){
                    setErrors(json.error);
                }

                stateObj.value = json;
            }           
            
        } catch(e){
            console.warn(e);
            stateObj.value.multiFactorState = 0;
        }
    }

    function testCapsLock(event) {        
        if(!event || typeof event.getModifierState !== 'function'){
            return;
        }
        capsLockOn.value = event.getModifierState("CapsLock");
        console.log('CAPSLOCK is: ' + (capsLockOn.value == true ? 'ON' : 'OFF'));
    }

    document.addEventListener("keydown", testCapsLock);
    // document.addEventListener("keyup", testCapsLock);

    function openDialog() {
        dialogRef.value?.show();
    }

    function closeDialog() {
        console.log("Closing login dialog...");
        if(dialogRef.value?.close){
            dialogRef.value?.close();
        }
        if(window.parent.window['af']) {
            window.parent.window['af'].userSession.authenticated();
        }
        if(window.parent.document.getElementById('o365-login-iframe') != null) {
            window.parent.document.getElementById('o365-login-iframe')?.remove();
        } else if(isIframe && window.parent.document.getElementsByClassName('session-expired-container').length){
            window.parent.document.getElementsByClassName('session-expired-container')[0].remove();
        } else if(isLoginArticle || isMfaArticle){
            location.replace(returnUrl);
        }
        emit('close');
    }

    onMounted(async() => {
        openDialog();
    });

    onBeforeMount(async () => {
        await getState();
    });
    
    onUnmounted(() => {
        console.log("LOGIN APP Unmount");
        emit('close');
    });
    
    defineExpose({openDialog, closeDialog})

</script>


